/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "../../static/styles/main.scss"
import logo from "../../static/images/logo.svg"
import iconTwitter from "../../static/images/icon-twitter.svg"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-5"}>
                <div className={"widget__item"}>
                  <div className={"logo"}>
                    <Link to="/" title={"McPiper"}>
                      <img alt={"Logo"} src={logo} />
                    </Link>
                  </div>

                  <div className={"about"}>
                    <p>
                      Monitor GitLab CI/CD pipelines.
                      <br />
                      <br />
                      Built by <a href="https://oleggera.com/">
                        Oleg Gera
                      </a>{" "}
                      during COVID-19 lockdown in London, UK.
                    </p>
                  </div>
                </div>
              </div>

              <div className={"col-2"}>
                <div className={"widget__item"}>
                  <ul className={"links"}>
                    <h4>McPiper</h4>
                    <ul>
                      <li>
                        <Link to="/release-notes/" title="Release notes">
                          Release notes
                        </Link>
                      </li>
                      <li>
                        <Link to="/roadmap/" title={"Roadmap"}>
                          Roadmap
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/feedback/"
                          className="links__special"
                          target="_blank"
                          rel="noreferrer"
                          title="We look forward to receiving your great feedback"
                        >
                          Feedback
                        </Link>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>

              <div className={"col-2"}>
                <div className={"widget__item"}>
                  <div className={"links"}>
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <Link to="/faqs/" title={"Frequently Asked Questions"}>
                          FAQs
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact/" title={"Contact Us"}>
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy/" title={"Privacy Policy"}>
                          Privacy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={"col-3"}>
                <div className={"widget__item"}>
                  <div className={"social"}>
                    <a
                      href="https://twitter.com/gerlv"
                      target="_blank"
                      rel="noreferrer"
                      title="Twitter"
                    >
                      <img alt={"Twitter"} src={iconTwitter} />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className={"copyright"}>
              <p>
                Copyright {new Date().getFullYear()}, {` `}{" "}
                <a href="https://oleggera.com/" title={"Oleg Gera"}>
                  Oleg Gera
                </a>
                . All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
