import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../static/images/logo.svg"
import macOSAppStoreBadge from "../../static/images/Mac_App_Store_Badge.svg"

const Header = ({ siteTitle }) => (
  <header>
    <div className={"container"}>
      <div className={"top-menu"}>
        <div className={"logo"}>
          <Link to="/" title={"McPiper"}>
            <img alt={"Logo"} src={logo} />
          </Link>
        </div>

        <div className="right">
          <a
            href={"https://apps.apple.com/us/app/mcpiper/id1517471189?ls=1"}
            title="Download McPiper on Mac App Store"
          >
            <img alt="Mac App Store badge" src={macOSAppStoreBadge} />
          </a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
